import React from "react";
import Img from "../assets/images/header_logo.png";
import Img1 from "../assets/images/comingsoon-text.png";
import Fade from "react-reveal";
import Layout from "../Components/Layout/Layout";

const ComingSoonPage = ({location}) => {
  // console.log(location,"Sad")
  return (
    <Layout location={location}>
      <div className="bg-coming-soon-img h-screen">
        <div className=" max-w-6xl m-auto overflow-hidden h-full">
          <div className=" h-full flex justify-center items-center flex-col px-4">
            <Fade top>
              <div className="">
                <img src={Img} className="w-28 h-36" alt="icon" />
              </div>
            </Fade>
            <Fade top>
              <div className="pb-10">
                <img src={Img1} alt="img" />
              </div>
            </Fade>
            <Fade bottom>
              <div className="text-xl lg:text-6xl text-center font-bold text-white font-Niconne pb-10">
                Get Ready for next big thing in AVGSS Hospitality and Restaurant LLP.
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ComingSoonPage;
